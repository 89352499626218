import React, { useState, useEffect } from 'react';
import loadingGif from './loading.gif';
import FileUpload from './FileUpload';
import './App.css';
import CopyToClipboardButton from './CopyToClipboardButton';
import ResumeJobOptimizer from './ResumeJobOptimizer';

const ResumeFeedbackGenerator = ({ baseUrl }) => {
  const BASE_URL = baseUrl;

  const [resume, setResume] = useState('');
  const [feedback, setFeedback] = useState(null);
  const [feedbackError, setFeedbackError] = useState(null);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [isRegenerating, setIsRegenerating] = useState(false);



//save resume to local storage
  useEffect(() => {
    const savedResume = localStorage.getItem('resume');
    if (savedResume) {
      setResume(savedResume);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('resume', resume);
  }, [resume]);


  const startTimer = (setLoadingMessage) => {
    setLoadingMessage('Loading...');
    setTimeout(() => {
      setLoadingMessage("Hang tight! Still Generating Feedback.");
    }, 15000);
  };

    const generateresumeFeedback = async (regenerating = false) => {
      setIsRegenerating(regenerating);


    if (!resume) {
      setFeedbackError('Please make sure to upload or paste your resume.');
      return;
    }

    setFeedbackLoading(true); // Start the loading state
    let timerId = startTimer(setLoadingMessage);

    try {
      const response = await fetch(`${BASE_URL}/generate-resume-feedback`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ resume: resume.replace(/\n/g, ' ') }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const feedbackResponse = await response.json();
      setFeedback(feedbackResponse.feedback);
    } catch (error) {
      console.error('There was a problem with the fetch operation: ', error);
      setFeedbackError(error.message);
    }  finally {
      clearTimeout(timerId);
      setFeedbackLoading(false);  // End the loading state
      setLoadingMessage('');
    }
  };

  return (
    <div className="ComponentContainer">
      <div className="ResumeFeedbackGenerator">
      <h1>Resume Feedback</h1>
      <div className="input-section">
        <div id="dropbox" className='db clickable'>
          <FileUpload onTextExtracted={(text) => setResume(text)} />
        </div>
        <div className="input-form">
          <div className="input-group">
            <label htmlFor="resumeInput" className='inputlabel'>Resume:</label>
            <textarea
              id="resumeInput"
              value={resume}
              onChange={(e) => setResume(e.target.value)}
              placeholder="Copy & paste your resume..."
              className='textarea-large'
              maxLength={5000}
            />
          </div>

          {feedbackLoading && (
            <div className="loading-container">
              <img className="loading-gif" src={loadingGif} alt="Loading..." />
              <div className="loading-message">{loadingMessage}</div>
            </div>
          )}

          <div className="button-group">
          <button type="button" className='button-2' onClick={() => generateresumeFeedback(false)}>Generate Feedback</button>
          </div>

          {feedbackError && (
            <p className="error-message">
              {feedbackError}
            </p>
          )}
        </div>
      </div>
      <div className="generative-section">
        {feedback && (
          <div className="result-container">
            <h2>Generated Feedback:</h2>
            <p dangerouslySetInnerHTML={{ __html: feedback }}></p>
            <CopyToClipboardButton textToCopy={feedback} />
            <button type="button" className='button-2' onClick={() => generateresumeFeedback(true)}>Regenerate Feedback</button>

            {feedbackLoading && isRegenerating && (
              <div className="loading-container">
                  <img className="loading-gif" src={loadingGif} alt="Loading..." />
                  <div className="loading-message">{loadingMessage}</div>
              </div>
            )}




          </div>
        )}
         </div>
      </div>

      <div className="divider"></div>
      <ResumeJobOptimizer baseUrl={baseUrl} resume={resume} />
    </div>
  );
}

export default ResumeFeedbackGenerator;
