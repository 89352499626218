import React, { useState, useEffect, useCallback } from 'react';
import FileUpload from './FileUpload';
import './App.css';
import CopyToClipboardButton from './CopyToClipboardButton';
import { useAuth0 } from "@auth0/auth0-react";
import SkeletonLoader from './SkeletonLoader';
import { AiOutlineClose } from 'react-icons/ai';
import { AiOutlinePlus } from 'react-icons/ai';


const ResumeAlignment = ({ baseUrl, setMessage, setMessageType, setIsLoading, isLoading, loadingMessage, setLoadingMessage }) => {
  const BASE_URL = baseUrl;


  const { getAccessTokenSilently, user } = useAuth0();

  //states set for functions
  const [resume, setResume] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [alignment, setAlignment] = useState(null);
  const [jobTitle, setJobTitle] = useState(''); // State variable for job title

  

// instructions variable and state for showing and hiding instructions
  const [showInstructions, setShowInstructions] = useState(true);


  //transition rendering of result

const [showSkeleton, setShowSkeleton] = useState(false);
const [showContent, setShowContent] = useState(false);


//state for saved alignments
const [savedAlignments, setSavedAlignments] = useState([]);


//ref for scroll to loading
const loadingRef = React.useRef(null);

//ref for scroll to saved results
const savedResultsRef = React.useRef(null);



//expandable saved results section
const [savedResultsExpanded, setSavedResultsExpanded] = useState(false);

// Flag to track whether the user has fetched saved alignments before
const [hasFetchedBefore, setHasFetchedBefore] = useState(false);

// State variable for the textarea focus expander
const [focusedTextArea, setFocusedTextArea] = useState('');







useEffect(() => {
  if (isLoading) {
    setShowSkeleton(true);
  } else {
    // Start fade-out of the skeleton
    setShowSkeleton(false);
    // After the skeleton fades out, start the fade-in of the content
    setTimeout(() => {
      setShowContent(true);
    }, 500);
  }
}, [isLoading]);





  const resetErrors = () => {
    setMessage('');
  };

  //useeffect for fetching user data from db and setting state variables for resume, job description, and job title
  useEffect(() => {
    const fetchUserData = async () => {
      if (user && user.email) {
        const token = await getAccessTokenSilently({
          audience: "https://dev-jobsai.us.auth0.com/api/v2/",
          scope: "openid profile email",
        });

        try {
          const response = await fetch(`${BASE_URL}/users/find-user?email=${user.email}&auth0Id=${user.sub}&name=${user.name}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });

          if (response.ok) {
            const data = await response.json();
            if (data.resume) {
              setResume(data.resume);
            }
            if (data.jobDescription) {
              setJobDescription(data.jobDescription);
            }
            if (data.jobTitle) {
              setJobTitle(data.jobTitle);
            }

          } else {
            const errorData = await response.json();
            setMessage(errorData.error || 'Failed to fetch user data.');
            setMessageType('error');
          }
        } catch (error) {
          setMessage(error.message);
          setMessageType('error');
        }
      }
    };
    fetchUserData();
  }, [user, getAccessTokenSilently, BASE_URL, setMessage, setMessageType]);


//save job description to db
  const saveJobDescription = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: "https://dev-jobsai.us.auth0.com/api/v2/",
        scope: "openid profile email",
      });

      const response = await fetch(`${BASE_URL}/users/modify-profile`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: user.email, jobDescription, jobTitle }), // Include jobTitle here
      });

      if (response.ok) {
        setMessage('Job Description Saved Successfully.');
        setMessageType('success');
      } else {
        const errorData = await response.json();
        setMessage(errorData.error || 'Failed to Save Job Description.');
        setMessageType('error');
      }
    } catch (error) {
      setMessage(error.message);
      setMessageType('error');
    }
  };

  const saveResume = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: "https://dev-jobsai.us.auth0.com/api/v2/",
        scope: "openid profile email",
      });

      const response = await fetch(`${BASE_URL}/users/modify-profile`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: user.email, resume }),
      });

      if (response.ok) {
        setMessage('Resume Saved Successfully.');
        setMessageType('success');
      } else {
        const errorData = await response.json();
        setMessage(errorData.error || 'Failed to save resume.');
        setMessageType('error');
      }
    } catch (error) {
      setMessage(error.message);
      setMessageType('error');
    }
  };

  const checkResumeAlignment = async () => {
    resetErrors();

    if (!jobDescription || !resume) {
      setMessage('Please make sure to populate both the job description and resume fields.');
      setMessageType('error');
      return;
    }

    let timerId = startTimer(setLoadingMessage);

       
    try {

      setIsLoading(true);
      setShowInstructions(false);
      scrollToGenerative();

                   // Scroll to the loading section's position
             if (loadingRef.current) {
              window.scrollTo({
                top: loadingRef.current.offsetTop,
                behavior: 'smooth',
              });
            }


      const token = await getAccessTokenSilently({
        audience: "https://dev-jobsai.us.auth0.com/api/v2/",
        scope: "openid profile email",
      });

      const response = await fetch(`${BASE_URL}/check-resume-alignment`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ jobDescription, resume }),
      });

      const data = await response.json();
      setAlignment(data.alignment);
    } catch (error) {
      setMessage('Error fetching alignment: ' + error.message);
      setMessageType('error');
    } finally {
      clearTimeout(timerId);
      setIsLoading(false);
      setLoadingMessage('');


    }
  }; 


 const saveJobData = async () => {
    const token = await getAccessTokenSilently({
      audience: "https://dev-jobsai.us.auth0.com/api/v2/",
      scope: "openid profile email",
    });
  
    // Create the job data object
    const jobData = {
      userId: user.sub, //auth0Id
      jobTitle, // Replace with the actual job title
      jobDescription, // Using the state variable
      resumeAlignment: alignment, // Using the state variable
    };
  
    try {

      if (!savedAlignments.find(item => item.jobTitle === jobTitle)) {

      // Call the new API endpoint to save the job data
      const response = await fetch(`${BASE_URL}/users/saveJobData`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jobData),
      });
  
      // Parse JSON response
      const responseData = await response.json();
  
      if (response.status === 200 || response.status === 201) {
        // Handle success
        setMessage("Job data successfully saved.");
        setMessageType("success");
        // Add the saved alignment to the list of saved alignments
        setSavedAlignments([{ jobTitle, resumeAlignment: alignment }, ...savedAlignments]);

      } else {
        // Handle error
        setMessage(`Failed to save job data: ${responseData.message}`);
        setMessageType("error");
      }
    } else {
      setMessage(`Job data for ${jobTitle} already exists. Please change the job title to save a new result.`);
      setMessageType("error");
    }
    
    } catch (error) {
      // Handle fetch errors
      setMessage(`An error occurred: ${error.message}`);
      setMessageType("error");
    }
  };

  //deletes saved alignment from jobdata object
  const deleteSavedResult = async (componentName, dataIdentifier) => {

            const isConfirmed = window.confirm(`Are you sure you want to delete the data for ${dataIdentifier}?`);
          
          // If the user clicked "Cancel", then exit the function
          if (!isConfirmed) {
            return;
          }

    try {
        const token = await getAccessTokenSilently({
            audience: "https://dev-jobsai.us.auth0.com/api/v2/",
            scope: "openid profile email",
        });

        const response = await fetch(`${BASE_URL}/users/deleteSavedResult`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId: user.sub, componentName, dataIdentifier }),
        });

        if (response.ok) {
            // Remove the deleted alignment from the state
            setSavedAlignments(prevAlignments => prevAlignments.filter(item => item.jobTitle !== dataIdentifier));

            setMessage(`${componentName} data deleted successfully.`);
            setMessageType('success');
        } else {
            const errorData = await response.json();
            setMessage(errorData.error || `Failed to delete ${componentName} data.`);
            setMessageType('error');
        }
    } catch (error) {
        setMessage(`An error occurred: ${error.message}`);
        setMessageType("error");
    }
};


  
  
  //fetches saved alignments and job title
  
  const fetchSavedAlignments = useCallback(async () => {
    const token = await getAccessTokenSilently({
      audience: "https://dev-jobsai.us.auth0.com/api/v2/",
      scope: "openid profile email",
    });
    
    try {
      const response = await fetch(`${BASE_URL}/users/getAllJobData/${user.sub}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      
      const data = await response.json();
      
      // Only keep the 'jobTitle' and 'resumeAlignment' fields for each item
      const filteredData = data.jobDataList.map(item => ({
        jobTitle: item.jobTitle,
        resumeAlignment: item.resumeAlignment,
      }));
      
      setSavedAlignments(filteredData);
      
    } catch (error) {
      console.error("Failed to fetch saved alignments: ", error);
    }
  }, [user, getAccessTokenSilently, BASE_URL]);
  
  
  
  // This will refetch saved alignments whenever the user clicks the "Saved Results" section
  useEffect(() => {
    if (savedResultsExpanded && !hasFetchedBefore) {
      fetchSavedAlignments();
      setHasFetchedBefore(true); // Set the flag after fetching
    }
  }, [savedResultsExpanded, hasFetchedBefore, user, fetchSavedAlignments]);



//timer to pause a second then scroll to generative section
const startTimer = (setLoadingMessage) => {
  setLoadingMessage('');
  setTimeout(() => {
    setLoadingMessage("Still Generating Results...");
  }, 15000);
};

//timer to pause a second then scroll to generative section
const scrollToGenerative = () => {
  setTimeout(() => {
    if (loadingRef.current) {
      window.scrollTo({
        top: loadingRef.current.offsetTop - 125,
        behavior: 'smooth',
      });
    }
  }, 25);
};



const handleToggleSavedResults = () => {
  setSavedResultsExpanded(prevState => !prevState);

  // If the saved results are not expanded, scroll to them
  if (!savedResultsExpanded) {
      setTimeout(() => {
          if (savedResultsRef.current) {
              window.scrollTo({
                  top: savedResultsRef.current.offsetTop - 100, // Offset by 100px
                  behavior: 'smooth',
              });
          }
      }, 25); // A short delay to ensure the section is rendered before scrolling
  }
};




  return (
    
    <div className="ComponentContainer">


        <h1>Resume Alignment Checker</h1>
        {/* <div className="button-3" onClick={() => setShowInstructions(!showInstructions)}> */}


        <div className="input-section">
          <div id="dropbox" className='db clickable'>
            <FileUpload onTextExtracted={(text) => setResume(text)} />
          </div>
          
          <div className="input-form">

            <div className="input-group">
              
            <div className='titleandsave'>
              <label htmlFor="resumeInput" className='inputlabel'>Resume:</label>
              <div className="button-3" onClick={saveResume}>Save Resume</div>
            </div>

                          <textarea
                              id="resumeInput"
                              value={resume}
                              onChange={(e) => setResume(e.target.value)}
                              placeholder="Copy & paste the resume..."
                              className={`textarea-large ${focusedTextArea === 'resume' ? 'expanded' : ''}`}
                              maxLength={5000}
                              onFocus={() => setFocusedTextArea('resume')}
                              onBlur={() => setFocusedTextArea('')}
                          />


            </div>
            <div className="input-group">

              <div className='titleandsave'>
                  <label className='inputlabel'>Job You're Interested In: <br></br><h4>Title & Description</h4></label>
                  
                  <div className="button-3" onClick={saveJobDescription}>Save Job Description</div>
              </div>



              <input
                id="jobTitleInput"
                type="text"
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
                placeholder="Enter job title..." />

              {/* JobDescription Input */}
                          <textarea
                              id="jobDescriptionInput"
                              value={jobDescription}
                              onChange={(e) => setJobDescription(e.target.value)}
                              placeholder="Copy & paste the job description..."
                              className={`textarea-large ${focusedTextArea === 'jobDescription' ? 'expanded' : ''}`}
                              maxLength={8000}
                              onFocus={() => setFocusedTextArea('jobDescription')}
                              onBlur={() => setFocusedTextArea('')}
                          />

                      
            </div>


            <div className="button-group">
              <button type="button" className='button-2' onClick={checkResumeAlignment}>Check Alignment</button>
            </div>

          </div>
        </div>



        <div className={showInstructions ? "instructions instructions-fadein" : "instructions instructions-fadeout"}>
          <h3>What to Expect:</h3>

          <li>Make sure your resume and job description are updated on the profile page.</li>
          <li>Click 'Check Alignment'.</li>
          <br></br>
          <li>The system will evaluate the alignment of your resume with the job description.</li>
          <li>You'll receive a detailed assessment of your qualifications versus job requirements.</li>

          <p><strong>Note:</strong> For best results, ensure your resume and job description are current and accurate.</p>
        </div>


        <div className="generative-section" ref={loadingRef}>
          {isLoading && (
            <div className={`result-container ${showSkeleton ? 'fadein' : 'fadeout'}`}>
              <div className='result-inner'>
                <h2>Generating Results...</h2>
                <SkeletonLoader className="animated-background" />
              </div>
            </div>
          )}
          {!isLoading && alignment && (
            <div className={`result-container ${showContent ? 'fadein' : 'fadeout'}`}>
              <div className='result-inner'>
                <h2> Resume Alignment:<br></br> {jobTitle}</h2>
                <div className='divider'></div>
                <p dangerouslySetInnerHTML={{ __html: alignment }}></p>
              </div>
              <CopyToClipboardButton textToCopy={alignment} />
              <button type="button" className='button-2' onClick={saveJobData}>Save</button> {/* New Save button */}

            </div>
          )}



          <div className='saved-result-section' ref={savedResultsRef}>
              <div className='save-title-expand'>
            <h1 onClick={handleToggleSavedResults}>Saved Results</h1>
                  <div className='expand-collapse-saved-results' onClick={handleToggleSavedResults}>
                      {savedResultsExpanded ? <AiOutlineClose></AiOutlineClose> : <AiOutlinePlus></AiOutlinePlus>}
                  </div>

                  </div>
                  <div className='divider'></div>

            {savedResultsExpanded && savedAlignments.length > 0 && (
              <>
                {savedAlignments.map((item, index) => (
                  <div key={index} className="saved-jobdata-item fadein">
                    <div className="result-container fadein">
                      <div className='result-inner'>
                       <div className='title-close'> <h2>{item.jobTitle}</h2>   <div className='saved-close-button' onClick={() => deleteSavedResult('resumeAlignment', item.jobTitle)}><AiOutlineClose></AiOutlineClose></div> </div>
                        <div className='divider'></div>
                        <p dangerouslySetInnerHTML={{ __html: item.resumeAlignment }}></p>
                        <CopyToClipboardButton buttonClass='button-1' textToCopy={item.resumeAlignment} />
                      </div>
                      <div className='divider'></div>

                    </div>
                  </div>
                ))}
              </>
            )}

          </div>
        </div>

      </div>
  );
};

export default ResumeAlignment;
