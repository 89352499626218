import React, { useState, useEffect } from 'react';
import loadingGif from './loading.gif';
import './App.css';
import CopyToClipboardButton from './CopyToClipboardButton';
import { useAuth0 } from "@auth0/auth0-react";
import SkeletonLoader from './SkeletonLoader';

const CompanyAnalysisGenerator = ({ baseUrl }) => {
  const BASE_URL = baseUrl;
  const { getAccessTokenSilently, user } = useAuth0();

  const [jobDescription, setJobDescription] = useState('');
  const [analysis, setAnalysis] = useState(null);
  const [analysisLoading, setAnalysisLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // Can be 'success' or 'error'

  // Transition rendering of result
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [showContent, setShowContent] = useState(false);

  // Ref for scroll to loading
  const loadingRef = React.useRef(null);
  
  
  
  // instructions variable and state for showing and hiding instructions


const [showInstructions, setShowInstructions] = useState(true);



  useEffect(() => {
    if (analysisLoading) {
      setShowSkeleton(true);
    } else {
      // Start fade-out of the skeleton
      setShowSkeleton(false);
      // After the skeleton fades out, start the fade-in of the content
      setTimeout(() => {
        setShowContent(true);
      }, 500);
    }
  }, [analysisLoading]);
  
  
  

  useEffect(() => {
    const fetchUserData = async () => {
      if (user && user.email) {
        const token = await getAccessTokenSilently({
          audience: "https://dev-jobsai.us.auth0.com/api/v2/",
          scope: "openid profile email",
        });

        try {
          const response = await fetch(`${BASE_URL}/users/find-user?email=${user.email}&auth0Id=${user.sub}&name=${user.name}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });

          if (response.ok) {
            const data = await response.json();
            if (data.jobDescription) {
              setJobDescription(data.jobDescription);
            }
          } else {
            const errorData = await response.json();
            setMessage(errorData.error || 'Failed to fetch user data.');
            setMessageType('error');
          }
        } catch (error) {
          setMessage(error.message);
          setMessageType('error');
        }
      }
    };
    fetchUserData();
  }, [user, getAccessTokenSilently, BASE_URL]);

  const resetErrors = () => {
    setMessage('');
  };

  const startTimer = (setLoadingMessage) => {
    setLoadingMessage('');
    setTimeout(() => {
      setLoadingMessage("Hang tight! Still Generating Results.");
    }, 15000);
  };

  const scrollToGenerative = () => {
    setTimeout(() => {
      if (loadingRef.current) {
        window.scrollTo({
          top: loadingRef.current.offsetTop,
          behavior: 'smooth',
        });
      }
    }, 1000);
  };

  const saveJobDescription = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: "https://dev-jobsai.us.auth0.com/api/v2/",
        scope: "openid profile email",
      });

      const response = await fetch(`${BASE_URL}/users/modify-profile`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: user.email, jobDescription }),
      });

      if (response.ok) {
        setMessage('Job Description Saved Successfully.');
        setMessageType('success');
      } else {
        const errorData = await response.json();
        setMessage(errorData.error || 'Failed to Save Job Description.');
        setMessageType('error');
      }
    } catch (error) {
      setMessage(error.message);
      setMessageType('error');
    }
  };

  const generateCompanyAnalysis = async () => {
    resetErrors();

    if (!jobDescription) {
      setMessage('Please make sure to populate the job description field.');
      setMessageType('error');
      return;
    }

    let timerId = startTimer(setLoadingMessage);

    try {
      setAnalysisLoading(true);
      scrollToGenerative();
      setShowInstructions(false);

      const token = await getAccessTokenSilently({
        audience: "https://dev-jobsai.us.auth0.com/api/v2/",
        scope: "openid profile email",
      });

      const response = await fetch(`${BASE_URL}/generate-company-analysis`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ jobDescription }),
      });

      const data = await response.json();
      setAnalysis(data.analysis);
    } catch (error) {
      setMessage('Error fetching company analysis: ' + error.message);
      setMessageType('error');
    } finally {
      clearTimeout(timerId);
      setAnalysisLoading(false);
      setLoadingMessage('');
    }
  };

  return (
    <div className="ComponentContainer">
      <h1>Company Analysis Generator</h1>
      <div className="input-section">
        <div className="input-form">
          <div className="input-group">
            <label htmlFor="jobDescriptionInput" className='inputlabel'>Job Description:</label>
            <textarea
              id="jobDescriptionInput"
              value={jobDescription}
              onChange={(e) => setJobDescription(e.target.value)}
              placeholder="Copy & paste the job description..."
              className='textarea-large'
              maxLength={5000}
            />
            <div className="button-3" onClick={saveJobDescription}>Save Job Description</div>
          </div>
          {message && (
            <p className={messageType === 'success' ? 'success-message' : 'error-message'}>
              {message}
            </p>
          )}
          <div className="button-group">
            <button type="button" className='button-2' onClick={generateCompanyAnalysis}>Generate Analysis</button>
          </div>
          {analysisLoading && (
            <div className="loading-container">
              <img className="loading-gif" src={loadingGif} alt="Loading..." />
              <div className="loading-message">{loadingMessage}</div>
            </div>
          )}
        </div>
      </div>
      
<div className={showInstructions ? "instructions instructions-fadein" : "instructions instructions-fadeout"}>
      <h3>What to Expect:</h3>

        <li>Make sure your resume and job description are updated on the profile page.</li>
        <li>Click 'Check Alignment'.</li>
<br></br>   
        <li>The system will evaluate the alignment of your resume with the job description.</li>
        <li>You'll receive a detailed assessment of your qualifications versus job requirements.</li>
  
    <p><strong>Note:</strong> For best results, ensure your resume and job description are current and accurate.</p>
</div>

      
      
      
      
      

      <div className="generative-section" ref={loadingRef}>
        {analysisLoading && (
          <div className={`result-container ${showSkeleton ? 'fadein' : 'fadeout'}`}>
           <div className="result-inner">
            <SkeletonLoader className="animated-background" />
            </div>
          </div>
        )}
        {!analysisLoading && analysis && (
          <div className={`result-container ${showContent ? 'fadein' : 'fadeout'}`}>
            <div className="result-inner">
            <h2>Generated Company Analysis:</h2>
            <p dangerouslySetInnerHTML={{ __html: analysis }}></p>
            </div>
            <CopyToClipboardButton textToCopy={analysis} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyAnalysisGenerator;
