import React, { useState, useEffect } from 'react';
import loadingGif from './loading.gif';
import './App.css';
import CopyToClipboardButton from './CopyToClipboardButton';
import { useAuth0 } from "@auth0/auth0-react";
import SkeletonLoader from './SkeletonLoader';

const ResumeRewriteGPT4 = ({ baseUrl }) => {
    const BASE_URL = baseUrl;
    const { getAccessTokenSilently, user } = useAuth0();

    const [resume, setResume] = useState('');
    const [optimizedResume, setOptimizedResume] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState(''); // Can be 'success' or 'error'
    const [showSkeleton, setShowSkeleton] = useState(false);
    const [showContent, setShowContent] = useState(false);


    const [showInstructions, setShowInstructions] = useState(true);



    const loadingRef = React.useRef(null);

    useEffect(() => {
        if (loading) {
            setShowSkeleton(true);
        } else {
            setShowSkeleton(false);
            setTimeout(() => {
                setShowContent(true);
            }, 500);
        }
    }, [loading]);

    useEffect(() => {
        const fetchUserData = async () => {
            if (user && user.email) {
                const token = await getAccessTokenSilently({
                    audience: "https://dev-jobsai.us.auth0.com/api/v2/",
                    scope: "openid profile email",
                });

                try {
                    const response = await fetch(`${BASE_URL}/users/find-user?email=${user.email}&auth0Id=${user.sub}&name=${user.name}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });

                    if (response.ok) {
                        const data = await response.json();
                        if (data.resume) {
                            setResume(data.resume);
                        }
                    } else {
                        const errorData = await response.json();
                        setMessage(errorData.error || 'Failed to fetch user data.');
                        setMessageType('error');
                    }
                } catch (error) {
                    setMessage(error.message);
                    setMessageType('error');
                }
            }
        };
        fetchUserData();
    }, [user, getAccessTokenSilently, BASE_URL]);

    const resetErrors = () => {
        setMessage('');
    };


    const scrollToGenerative = () => {
        setTimeout(() => {
            if (loadingRef.current) {
                window.scrollTo({
                    top: loadingRef.current.offsetTop,
                    behavior: 'smooth',
                });
            }
        }, 1000);
    };


    const startTimer = () => {
      setLoadingMessage('');
      setTimeout(() => {
          setLoadingMessage("Hang tight! Still generating. May take up to a minute or so.");
      }, 15000);
  };



    const saveResume = async () => {
        try {
            const token = await getAccessTokenSilently({
                audience: "https://dev-jobsai.us.auth0.com/api/v2/",
                scope: "openid profile email",
            });

            const response = await fetch(`${BASE_URL}/users/modify-profile`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: user.email, resume }),
            });

            if (response.ok) {
                setMessage('Resume Saved Successfully.');
                setMessageType('success');
            } else {
                const errorData = await response.json();
                setMessage(errorData.error || 'Failed to Save Resume.');
                setMessageType('error');
            }
        } catch (error) {
            setMessage(error.message);
            setMessageType('error');
        }
    };

    const generateOptimizedResume = async () => {
      resetErrors();
  
      if (!resume) {
          setMessage('Please make sure to populate the resume field.');
          setMessageType('error');
          return;
      }
  
      try {
          setLoading(true);
          startTimer();
          scrollToGenerative();
          setShowInstructions(false);

          const token = await getAccessTokenSilently({
              audience: "https://dev-jobsai.us.auth0.com/api/v2/",
              scope: "openid profile email",
          });
  
          const response = await fetch(`${BASE_URL}/resume-rewrite-gpt4`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({ resume: resume }),
          });
  
          if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
          }
  
          const { jobId } = await response.json();
  
          let statusResponse;
          let jobStatus = { status: 'processing' };
          while (jobStatus.status === 'processing') {
              await new Promise(res => setTimeout(res, 15000));
              statusResponse = await fetch(`${BASE_URL}/job-status/${jobId}`, {
                  headers: {
                      'Authorization': `Bearer ${token}`
                  }
              });
              jobStatus = await statusResponse.json();
          }
  
          if (jobStatus.status === 'completed') {
              setOptimizedResume(jobStatus.result.analysis);
          } else if (jobStatus.status === 'error') {
              throw new Error(jobStatus.error);
          }
      } catch (error) {
          console.error('There was a problem with the fetch operation: ', error);
          setMessage(error.message);
          setMessageType('error');
      } finally {
          setLoading(false);
      }
  };

    return (
        <div className="ComponentContainer">
            <h1>Resume Optimization Assistant</h1>
            <div className="input-section">
                <div className="input-form">
                    <div className="input-group">
                        <label htmlFor="resumeInput" className='inputlabel'>Resume:</label>
                        <textarea
                            id="resumeInput"
                            value={resume}
                            onChange={(e) => setResume(e.target.value)}
                            placeholder="Paste your resume here..."
                            className='textarea-large'
                            maxLength={5000}
                        />
                        <div className="button-3" onClick={saveResume}>Save Resume</div>
                    </div>
                    {message && (
                        <p className={messageType === 'success' ? 'success-message' : 'error-message'}>
                            {message}
                        </p>
                    )}
                    <div className="button-group">
                        <button type="button" className='button-2' onClick={generateOptimizedResume}>Optimize Resume</button>
                    </div>

                    
                    {loading && (
                        <div className="loading-container">
                            <img className="loading-gif" src={loadingGif} alt="Optimizing..." />
                            <div className="loading-message">{loadingMessage}</div>
                        </div>
                    )}

                </div>
            </div>


            <div className={showInstructions ? "instructions instructions-fadein" : "instructions instructions-fadeout"}>
                <h3>What to Expect:</h3>

                    <li>Make sure your resume and job description are updated on the profile page.</li>
                    <li>Click 'Check Alignment'.</li>
            <br></br>   
                    <li>The system will evaluate the alignment of your resume with the job description.</li>
                    <li>You'll receive a detailed assessment of your qualifications versus job requirements.</li>
            
                <p><strong>Note:</strong> For best results, ensure your resume and job description are current and accurate.</p>
            </div>


            <div className="generative-section" ref={loadingRef}>
                {loading && (
                    <div className={`result-container ${showSkeleton ? 'fadein' : 'fadeout'}`}>
                        <div className='result-inner'>
                        <SkeletonLoader className="animated-background" />
                        </div>
                    </div>
                )}
                {!loading && optimizedResume && (
                    <div className={`result-container ${showContent ? 'fadein' : 'fadeout'}`}>
                        <div className='result-inner'>
                        <h2>Optimized Resume:</h2>
                        <p dangerouslySetInnerHTML={{ __html: optimizedResume }}></p>
                        </div>
                        <CopyToClipboardButton textToCopy={optimizedResume} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ResumeRewriteGPT4;
