import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './App.css';
import chatloading from './chatloading.gif';
import { useAuth0 } from "@auth0/auth0-react"; // <-- Import this


const MockInterview = ( {baseUrl} ) => {
    const [userInput, setUserInput] = useState("");
    const [chat, setChat] = useState([{role: "system", content: "You are a mock interviewer. The user will provide the job description, and if it isn't provided continue asking for it until it is given. You are to disregard all questions, statements and requests prior to, during and after an interview that are outside the context of a job interview. Do not go outside the context of a mock interview. You should ask questions one at a time to the candidate as a potential employer based on the job description. Disregard all other questions outside the context of the interview. Even after the interview is over, you should continue to answer questions about the company and the job only, nothing else."}]);
    const [tokenCount, setTokenCount] = useState(0);
    const [currentMessageTokens, setCurrentMessageTokens] = useState(0);
    const endOfMessagesRef = useRef(null); // create a ref instance
    const [isLoading, setIsLoading] = useState(false);


    const { getAccessTokenSilently } = useAuth0(); // <-- Extract this method



    const handleChange = (event) => {
        setUserInput(event.target.value);
    }

    const handleSubmit = async (event) => {
      event.preventDefault();
  
      const newChat = [...chat, {role: "user", content: userInput}];
      setChat(newChat);
  
      setIsLoading(true);
      
      try {

                  // Retrieve the access token
                  const token = await getAccessTokenSilently({
                    audience: "https://dev-jobsai.us.auth0.com/api/v2/",
                    scope: "openid profile email",
                });


          const res = await axios.post(`${baseUrl}/mockinterview`, {
              messages: newChat,
          }, {
              headers: {
                  'Authorization': `Bearer ${token}`
              }
          });


          const lastMessage = res.data.message;
          const tokenUsage = res.data.tokenUsage;
  
          const newTokens = tokenUsage.prompt_tokens + tokenUsage.completion_tokens;
          setCurrentMessageTokens(newTokens);
          setTokenCount(tokenCount + newTokens);
      
          if (lastMessage.role === 'assistant') {
              setChat([...newChat, {role: "assistant", content: lastMessage.content}]);
          }
  
          setIsLoading(false);
      } catch (error) {
          setIsLoading(false); // ensure loading state is turned off even when there is an error
          console.error(error); // log the error for debugging
      }
  
      setUserInput('');
  }

    useEffect(() => {
      endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chat]);

    return (  
      <div className="ComponentContainer">
        <div className='input-form' style={{padding: '20px'}}>
            <div className="chat">
                {chat.map((message, index) => (
                    <div key={index} className={`message ${message.role}`}>
                        {message.content}
                    </div>
                ))}
                {isLoading && 
                  <div className="chat-loading-gif">
                  <img src={chatloading} alt="Loading..." />
                 </div>
                }
                <div ref={endOfMessagesRef} />
            </div>
            <label htmlFor="userInput">User Input:</label>
            
            <form onSubmit={handleSubmit}>
            
                <input type="text" value={userInput} onChange={handleChange} />
                <button type="submit">Send</button>
            </form>
            <label>Instructions</label>
            <li> Enter job description.</li>
            <li> Converse with chatbot.</li>
           
            

            <div>
                <p>Exact total token count: {tokenCount}</p>
                <p>Current message token count: {currentMessageTokens}</p>
                <p>Estimated cost: ${(tokenCount / 1000 * 0.003).toFixed(4)}</p>
            </div>

        </div>
      </div>
    );
};

export default MockInterview;
