import React, { useState, useEffect } from 'react';
import loadingGif from './loading.gif';
import './App.css';
import CopyToClipboardButton from './CopyToClipboardButton';
import { useAuth0 } from "@auth0/auth0-react";
import SkeletonLoader from './SkeletonLoader';

const CoverLetterGenerator = ({ baseUrl }) => {
  const BASE_URL = baseUrl;
  const { getAccessTokenSilently, user } = useAuth0();

  const [resume, setResume] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [coverLetter, setCoverLetter] = useState(null);
  const [letterLoading, setLetterLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // Can be 'success' or 'error'

  // Transition rendering of result
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [showContent, setShowContent] = useState(false);

  // Ref for scroll to loading
  const loadingRef = React.useRef(null);
  
  // instructions variable and state for showing and hiding instructions

const [showInstructions, setShowInstructions] = useState(true);
  
  
  

  useEffect(() => {
    if (letterLoading) {
      setShowSkeleton(true);
    } else {
      // Start fade-out of the skeleton
      setShowSkeleton(false);
      // After the skeleton fades out, start the fade-in of the content
      setTimeout(() => {
        setShowContent(true);
      }, 500);
    }
  }, [letterLoading]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user && user.email) {
        const token = await getAccessTokenSilently({
          audience: "https://dev-jobsai.us.auth0.com/api/v2/",
          scope: "openid profile email",
        });

        try {
          const response = await fetch(`${BASE_URL}/users/find-user?email=${user.email}&auth0Id=${user.sub}&name=${user.name}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });

          if (response.ok) {
            const data = await response.json();
            if (data.resume) {
              setResume(data.resume);
            }
            if (data.jobDescription) {
              setJobDescription(data.jobDescription);
            }
          } else {
            const errorData = await response.json();
            setMessage(errorData.error || 'Failed to fetch user data.');
            setMessageType('error');
          }
        } catch (error) {
          setMessage(error.message);
          setMessageType('error');
        }
      }
    };
    fetchUserData();
  }, [user, getAccessTokenSilently, BASE_URL]);

  const resetErrors = () => {
    setMessage('');
  };

  const startTimer = (setLoadingMessage) => {
    setLoadingMessage('');
    setTimeout(() => {
      setLoadingMessage("Still Generating Cover Letter...");
    }, 15000);
  };

  const scrollToGenerative = () => {
    setTimeout(() => {
      if (loadingRef.current) {
        window.scrollTo({
          top: loadingRef.current.offsetTop,
          behavior: 'smooth',
        });
      }
    }, 1000);
  };

  const saveResume = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: "https://dev-jobsai.us.auth0.com/api/v2/",
        scope: "openid profile email",
      });

      const response = await fetch(`${BASE_URL}/users/modify-profile`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: user.email, resume }),
      });

      if (response.ok) {
        setMessage('Resume Saved Successfully.');
        setMessageType('success');
      } else {
        const errorData = await response.json();
        setMessage(errorData.error || 'Failed to save resume.');
        setMessageType('error');
      }
    } catch (error) {
      setMessage(error.message);
      setMessageType('error');
    }
  };

  const saveJobDescription = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: "https://dev-jobsai.us.auth0.com/api/v2/",
        scope: "openid profile email",
      });

      const response = await fetch(`${BASE_URL}/users/modify-profile`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: user.email, jobDescription }),
      });

      if (response.ok) {
        setMessage('Job Description Saved Successfully.');
        setMessageType('success');
      } else {
        const errorData = await response.json();
        setMessage(errorData.error || 'Failed to Save Job Description.');
        setMessageType('error');
      }
    } catch (error) {
      setMessage(error.message);
      setMessageType('error');
    }
  };

  const generateCoverLetter = async () => {
    resetErrors();

    if (!jobDescription || !resume) {
      setMessage('Please make sure to populate both the resume and job description fields.');
      setMessageType('error');
      return;
    }

    let timerId = startTimer(setLoadingMessage);

    try {
      setLetterLoading(true);
      scrollToGenerative();
      setShowInstructions(false);

      const token = await getAccessTokenSilently({
        audience: "https://dev-jobsai.us.auth0.com/api/v2/",
        scope: "openid profile email",
      });

      const response = await fetch(`${BASE_URL}/generate-cover-letter`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ resume, jobDescription }),
      });

      const data = await response.text();
      setCoverLetter(data);
    } catch (error) {
      setMessage('Error fetching cover letter: ' + error.message);
      setMessageType('error');
    } finally {
      clearTimeout(timerId);
      setLetterLoading(false);
      setLoadingMessage('');
    }
  };

  return (
    <div className="ComponentContainer">
      <h1>Cover Letter Generator</h1>
      <div className="input-section">
        <div className="input-form">
          <div className="input-group">
            <label htmlFor="resumeInput" className='inputlabel'>Resume:</label>
            <textarea
              id="resumeInput"
              value={resume}
              onChange={(e) => setResume(e.target.value)}
              placeholder="Copy & paste the resume..."
              className='textarea-large'
              maxLength={5000}
            />
            <div className="button-3" onClick={saveResume}>Save Resume</div>
          </div>
          <div className="input-group">
            <label htmlFor="jobDescriptionInput" className='inputlabel'>Job Description:</label>
            <textarea
              id="jobDescriptionInput"
              value={jobDescription}
              onChange={(e) => setJobDescription(e.target.value)}
              placeholder="Copy & paste the job description..."
              className='textarea-large'
              maxLength={5000}
            />
            <div className="button-3" onClick={saveJobDescription}>Save Job Description</div>
          </div>
          {message && (
            <p className={messageType === 'success' ? 'success-message' : 'error-message'}>
              {message}
            </p>
          )}
          <div className="button-group">
            <button type="button" className='button-2' onClick={generateCoverLetter}>Generate Cover Letter</button>
          </div>
          {letterLoading && (
            <div className="loading-container">
              <img className="loading-gif" src={loadingGif} alt="Loading..." />
              <div className="loading-message">{loadingMessage}</div>
            </div>
          )}
        </div>
      </div>
      
<div className={showInstructions ? "instructions instructions-fadein" : "instructions instructions-fadeout"}>
      <h3>What to Expect:</h3>

        <li>Make sure your resume and job description are updated on the profile page.</li>
        <li>Click 'Check Alignment'.</li>
<br></br>   
        <li>The system will evaluate the alignment of your resume with the job description.</li>
        <li>You'll receive a detailed assessment of your qualifications versus job requirements.</li>
  
    <p><strong>Note:</strong> For best results, ensure your resume and job description are current and accurate.</p>
</div>

      
      

      <div className="generative-section" ref={loadingRef}>
        {letterLoading && (
          <div className={`result-container ${showSkeleton ? 'fadein' : 'fadeout'}`}>
            <div className="result-inner">
            <SkeletonLoader className="animated-background" />
            </div>
          </div>
        )}
        {!letterLoading && coverLetter && (
          <div className={`result-container ${showContent ? 'fadein' : 'fadeout'}`}>
            <div className="result-inner">
            <h2>Generated Cover Letter:</h2>
            <p dangerouslySetInnerHTML={{ __html: coverLetter }}></p>
            </div>
            <CopyToClipboardButton textToCopy={coverLetter} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CoverLetterGenerator;
