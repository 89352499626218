import React, { useState, useEffect } from 'react';
import loadingGif from './loading.gif';
import './App.css';
import CopyToClipboardButton from './CopyToClipboardButton';
import { useAuth0 } from "@auth0/auth0-react";
import SkeletonLoader from './SkeletonLoader';

const ResumeFeedback = ({ baseUrl, message, setMessage, messageType, setMessageType }) => {
  const BASE_URL = baseUrl;
  const { getAccessTokenSilently, user } = useAuth0();

  const [resume, setResume] = useState('');
  const [feedback, setFeedback] = useState(null);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [updatedResume, setUpdatedResume] = useState(null);


  // Transition rendering of result
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [showContent, setShowContent] = useState(false);

  //states for updated resume loading
  const [updatedResumeLoading, setUpdatedResumeLoading] = useState(false);
  // states for updated resume  
  const [showUpdatedSkeleton, setShowUpdatedSkeleton] = useState(false);
  const [showUpdatedContent, setShowUpdatedContent] = useState(false);

  // Ref for scroll to loading
  const feedbackRef = React.useRef(null);
  const updatedfeedbackref = React.useRef(null);
  
 
// instructions variable and state for showing and hiding instructions

const [showInstructions, setShowInstructions] = useState(true);
  


  //skeleton loading for resume
  useEffect(() => {
    if (feedbackLoading) {
      setShowSkeleton(true);
    } else {
      // Start fade-out of the skeleton
      setShowSkeleton(false);
      // After the skeleton fades out, start the fade-in of the content
      setTimeout(() => {
        setShowContent(true);
      }, 500);
    }
  }, [feedbackLoading]);

  //skeleton loading for updated resume
  useEffect(() => {
    if (updatedResumeLoading) {
        setShowUpdatedSkeleton(true);
    } else {
        setShowUpdatedSkeleton(false);
        setTimeout(() => {
            setShowUpdatedContent(true);
        }, 500);
    }
}, [updatedResumeLoading]);



//fetching user data
  useEffect(() => {
    const fetchUserData = async () => {
      if (user && user.email) {
        const token = await getAccessTokenSilently({
          audience: "https://dev-jobsai.us.auth0.com/api/v2/",
          scope: "openid profile email",
        });

        try {
          const response = await fetch(`${BASE_URL}/users/find-user?email=${user.email}&auth0Id=${user.sub}&name=${user.name}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });

          if (response.ok) {
            const data = await response.json();
            if (data.resume) {
              setResume(data.resume);
            }
          } else {
            const errorData = await response.json();
            setMessage(errorData.error || 'Failed to fetch user data.');
            setMessageType('error');
          }
        } catch (error) {
          setMessage(error.message);
          setMessageType('error');
        }
      }
    };
    fetchUserData();
  }, [user, getAccessTokenSilently, BASE_URL, setMessage, setMessageType]);

//resetting errors
  const resetErrors = () => {
    setMessage('');
  };

  const startTimer = (setLoadingMessage) => {
    setLoadingMessage('');
    setTimeout(() => {
      setLoadingMessage("Still Generating... May take up to a minute or more.");
    }, 15000);
  };


  //Scroll to section being rendered
  const scrollToFeedback = () => {
    setTimeout(() => {
      if (feedbackRef.current) {
        window.scrollTo({
          top: feedbackRef.current.offsetTop,
          behavior: 'smooth',
        });
      }
    }, 1000);
  };

  const scrollToUpdatedResume = () => {
    setTimeout(() => {
        if (updatedfeedbackref.current) {
            window.scrollTo({
                top: updatedfeedbackref.current.offsetTop,
                behavior: 'smooth',
            });
        }
    }, 1000);
};

  const saveResume = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: "https://dev-jobsai.us.auth0.com/api/v2/",
        scope: "openid profile email",
      });

      const response = await fetch(`${BASE_URL}/users/modify-profile`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: user.email, resume }),
      });

      if (response.ok) {
        setMessage('Resume Saved Successfully.');
        setMessageType('success');
      } else {
        const errorData = await response.json();
        setMessage(errorData.error || 'Failed to save resume.');
        setMessageType('error');
      }
    } catch (error) {
      setMessage(error.message);
      setMessageType('error');
    }
  };

  const generateResumeFeedback = async () => {
    resetErrors();

    if (!resume) {
      setMessage('Please make sure to populate the resume field.');
      setMessageType('error');
      return;
    }

    let timerId = startTimer(setLoadingMessage);

    try {
      setFeedbackLoading(true);
      scrollToFeedback();
      setShowInstructions(false);

      const token = await getAccessTokenSilently({
        audience: "https://dev-jobsai.us.auth0.com/api/v2/",
        scope: "openid profile email",
      });

      const response = await fetch(`${BASE_URL}/generate-resume-feedback`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ resume }),
      });

      const data = await response.json();
      setFeedback(data.feedback);
    } catch (error) {
      setMessage('Error fetching feedback: ' + error.message);
      setMessageType('error');
    } finally {
      clearTimeout(timerId);
      setFeedbackLoading(false);
      setLoadingMessage('');
    }
  };

  const generateUpdatedResume = async () => {
    resetErrors();

    if (!resume || !feedback) {
        setMessage('Please ensure both the resume and feedback are available.');
        setMessageType('error');
        return;
    }

    setUpdatedResumeLoading(true);  // Start the loading state for the updated resume
    setLoadingMessage('Generating updated resume...');  // Set the initial loading message
    scrollToUpdatedResume();  // Scroll to the updated resume section

    try {
        startTimer(setLoadingMessage);

        const token = await getAccessTokenSilently({
            audience: "https://dev-jobsai.us.auth0.com/api/v2/",
            scope: "openid profile email",
        });

        const response = await fetch(`${BASE_URL}/generate-resume-from-feedback-long`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ resume, feedback }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const { jobId } = await response.json();

        let statusResponse;
        let jobStatus = { status: 'processing' };
        while (jobStatus.status === 'processing') {
            await new Promise(res => setTimeout(res, 10000));
            statusResponse = await fetch(`${BASE_URL}/job-status/${jobId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            jobStatus = await statusResponse.json();
        }

        if (jobStatus.status === 'completed') {
            setUpdatedResume(jobStatus.result.analysis);
        } else if (jobStatus.status === 'error') {
            throw new Error(jobStatus.error);
        }
    } catch (error) {
        console.error('There was a problem with the fetch operation: ', error);
        setMessage(error.message);
        setMessageType('error');
    } finally {
        setUpdatedResumeLoading(false);  // End the loading state for the updated resume
    }
};



  return (
    <div className="ComponentContainer">
      <h1>Resume Feedback Generator</h1>
      <div className="input-section">
        <div className="input-form">
          <div className="input-group">
            <label htmlFor="resumeInput" className='inputlabel'>Resume:</label>
            <textarea
              id="resumeInput"
              value={resume}
              onChange={(e) => setResume(e.target.value)}
              placeholder="Copy & paste the resume..."
              className='textarea-large'
              maxLength={5000}
            />
            <div className="button-3" onClick={saveResume}>Save Resume</div>
          </div>

          <div className="button-group">
            <button type="button" className='button-2' onClick={generateResumeFeedback}>Generate Feedback</button>
          </div>
          {feedbackLoading && (
            <div className="loading-container">
              <img className="loading-gif" src={loadingGif} alt="Loading..." />
              <div className="loading-message">{loadingMessage}</div>
            </div>
          )}
        </div>
      </div>
      
<div className={showInstructions ? "instructions instructions-fadein" : "instructions instructions-fadeout"}>
      <h3>What to Expect:</h3>

        <li>Make sure your resume and job description are updated on the profile page.</li>
        <li>Click 'Check Alignment'.</li>
<br></br>   
        <li>The system will evaluate the alignment of your resume with the job description.</li>
        <li>You'll receive a detailed assessment of your qualifications versus job requirements.</li>
  
    <p><strong>Note:</strong> For best results, ensure your resume and job description are current and accurate.</p>
</div>

 

      <div className="generative-section" ref={feedbackRef}>
    
     {feedbackLoading && (
  <div className={`result-container ${showSkeleton ? 'fadein' : 'fadeout'}`}>
    <div className='result-inner'>
    <SkeletonLoader className="animated-background" />
    </div>
  </div>
)}

      {!feedbackLoading && feedback && (
  <div className={`result-container ${showContent ? 'fadein' : 'fadeout'}`}>
    <div className='result-inner'>
    <h2>Generated Resume Feedback:</h2>
    <p dangerouslySetInnerHTML={{ __html: feedback }}></p>
    </div>
    <CopyToClipboardButton textToCopy={feedback} />
    <button className='button-2' onClick={generateUpdatedResume}>Generate Resume Based on Feedback</button>

    {updatedResumeLoading && (
  <div className="loading-container">
    <img className="loading-gif" src={loadingGif} alt="Loading..." />
    <div className="loading-message">{loadingMessage}</div>
  </div>
)}
  </div>
)}



{updatedResumeLoading && (
  <div ref={updatedfeedbackref} className={`result-container ${showUpdatedSkeleton ? 'fadein' : 'fadeout'}`}>
    <div className='result-inner'>
    <SkeletonLoader className="animated-background" />
    </div>
  </div>
)}

{!updatedResumeLoading && updatedResume && (
  <div className={`result-container ${showUpdatedContent ? 'fadein' : 'fadeout'}`}>
    <div className='result-inner'>
    <h2>Updated Resume:</h2>
    <p dangerouslySetInnerHTML={{ __html: updatedResume }}></p>
    </div>
    <CopyToClipboardButton textToCopy={updatedResume} />
  </div>
)}


        

      </div>
    </div>
  );
};

export default ResumeFeedback;
