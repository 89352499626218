import React, { useState, useEffect } from 'react';
import './App.css';
import { AiOutlineClose } from "react-icons/ai";

const MessageBanner = ({ message, messageType, onClose }) => {
  const [animationStatus, setAnimationStatus] = useState('bannerFadeIn');

  useEffect(() => {
      const fadeOutTimer = setTimeout(() => {
          setAnimationStatus('bannerFadeOut');
      }, 4000); // 4 seconds

      const closeTimer = setTimeout(onClose, 5000);

      return () => {
          clearTimeout(fadeOutTimer);
          clearTimeout(closeTimer);
      };
  }, [onClose]);

  return (
      <div className={`message-banner ${messageType} ${animationStatus}`}>
          <span>{message}</span>
          <button className="close-button" onClick={onClose}><AiOutlineClose></AiOutlineClose></button>
      </div>
  );
};


export default MessageBanner;
