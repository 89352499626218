import React, { useState, useEffect } from 'react';
import loadingGif from './loading.gif';
import './App.css';
import CopyToClipboardButton from './CopyToClipboardButton';
import { useAuth0 } from "@auth0/auth0-react";
import SkeletonLoader from './SkeletonLoader';

const InterviewQuestionsGenerator = ({ baseUrl }) => {
    const BASE_URL = baseUrl;
    const { getAccessTokenSilently, user } = useAuth0();

    const [jobDescription, setJobDescription] = useState('');
    const [questions, setQuestions] = useState(null);
    const [questionsLoading, setQuestionsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState(''); // Can be 'success' or 'error'
    const [resume, setResume] = useState('');
    const [answers, setAnswers] = useState(null);
    const [answersLoading, setAnswersLoading] = useState(false);
    const [answersLoadingMessage, setAnswersLoadingMessage] = useState('');  // New state

    // Transition rendering of result
    const [showSkeleton, setShowSkeleton] = useState(false);
    const [showContent, setShowContent] = useState(false);

    // State variables for answers section
    const [showAnswersSkeleton, setShowAnswersSkeleton] = useState(false);
    const [showAnswersContent, setShowAnswersContent] = useState(false);

    // Ref for scroll to loading
    const loadingRef = React.useRef(null);
    // Ref for answers section
    const answersRef = React.useRef(null);
    
    
 // instructions variable and state for showing and hiding instructions

const [showInstructions, setShowInstructions] = useState(true);



    useEffect(() => {
        if (questionsLoading) {
            setShowSkeleton(true);
        } else {
            setShowSkeleton(false);
            setTimeout(() => {
                setShowContent(true);
            }, 500);
        }
    }, [questionsLoading]);

    useEffect(() => {
        if (answersLoading) {
            setShowAnswersSkeleton(true);
        } else {
            setShowAnswersSkeleton(false);
            setTimeout(() => {
                setShowAnswersContent(true);
            }, 500);
        }
    }, [answersLoading]);

    const resetErrors = () => {
        setMessage('');
    };

    useEffect(() => {
        const fetchUserData = async () => {
            if (user && user.email) {
                const token = await getAccessTokenSilently({
                    audience: "https://dev-jobsai.us.auth0.com/api/v2/",
                    scope: "openid profile email",
                });

                try {
                    const response = await fetch(`${BASE_URL}/users/find-user?email=${user.email}&auth0Id=${user.sub}&name=${user.name}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });

                    if (response.ok) {
                        const data = await response.json();
                        if (data.jobDescription) {
                            setJobDescription(data.jobDescription);
                        }
                        if (data.resume) {
                            setResume(data.resume);
                        }
                    } else {
                        const errorData = await response.json();
                        setMessage(errorData.error || 'Failed to fetch user data.');
                        setMessageType('error');
                    }
                } catch (error) {
                    setMessage(error.message);
                    setMessageType('error');
                }
            }
        };
        fetchUserData();
    }, [user, getAccessTokenSilently, BASE_URL]);

    const startTimer = (setLoadingMessage) => {
        setLoadingMessage('');
        setTimeout(() => {
            setLoadingMessage("Still Generating...");
        }, 15000);
    };

    const startAnswersTimer = (setAnswersLoadingMessage) => {
        setAnswersLoadingMessage('');
        setTimeout(() => {
            setAnswersLoadingMessage("Still Generating...");
        }, 15000);
    };

    const scrollToGenerative = () => {
        setTimeout(() => {
            if (loadingRef.current) {
                window.scrollTo({
                    top: loadingRef.current.offsetTop,
                    behavior: 'smooth',
                });
            }
        }, 1000);
    };

    const scrollToAnswers = () => {
        setTimeout(() => {
            if (answersRef.current) {
                window.scrollTo({
                    top: answersRef.current.offsetTop,
                    behavior: 'smooth',
                });
            }
        }, 1000);
    };

    const generateInterviewQuestions = async () => {
        resetErrors();

        if (!jobDescription) {
            setMessage('Please make sure to populate the job description field.');
            setMessageType('error');
            return;
        }

        let timerId = startTimer(setLoadingMessage);
        try {
            setQuestionsLoading(true);
            scrollToGenerative();
            setShowInstructions(false);

            const token = await getAccessTokenSilently({
                audience: "https://dev-jobsai.us.auth0.com/api/v2/",
                scope: "openid profile email",
            });

            const response = await fetch(`${BASE_URL}/generate-interview-questions`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ jobDescription }),
            });

            const data = await response.json();
            setQuestions(data.questions);
        } catch (error) {
            setMessage('Error fetching interview questions: ' + error.message);
            setMessageType('error');
        } finally {
            clearTimeout(timerId);
            setQuestionsLoading(false);
            setLoadingMessage('');
        }
    };

    const generateInterviewAnswers = async () => {
        let timerId = startAnswersTimer(setAnswersLoadingMessage);  // Start timer for answers
        try {
            setAnswersLoading(true);
            scrollToAnswers();

            const token = await getAccessTokenSilently({
                audience: "https://dev-jobsai.us.auth0.com/api/v2/",
                scope: "openid profile email",
            });

            const response = await fetch(`${BASE_URL}/generate-interview-answers`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ questions, resume }),
            });

            const data = await response.json();
            setAnswers(data.answers);
        } catch (error) {
            setMessage('Error fetching interview answers: ' + error.message);
            setMessageType('error');
        } finally {
            clearTimeout(timerId);  // Clear timer for answers
            setAnswersLoading(false);
            setAnswersLoadingMessage('');  // Reset loading message for answers
        }
    };


    return (
        <div className="ComponentContainer">
            <h1>Interview Questions Generator</h1>

            <div className="input-section">
                <div className="input-form">
                    <div className="input-group">
                        <label htmlFor="jobDescriptionInput" className='inputlabel'>Job Description:</label>
                        <textarea
                            id="jobDescriptionInput"
                            value={jobDescription}
                            onChange={(e) => setJobDescription(e.target.value)}
                            placeholder="Paste the job description..."
                            className='textarea-large'
                            maxLength={5000}
                        />
                    </div>
                    {message && (
                        <p className={messageType === 'success' ? 'success-message' : 'error-message'}>
                            {message}
                        </p>
                    )}
                    <div className="button-group">
                        <button type="button" className='button-2' onClick={generateInterviewQuestions}>Generate Interview Questions</button>
                    </div>
                    {questionsLoading && (
                        <div className="loading-container">
                            <img className="loading-gif" src={loadingGif} alt="Loading..." />
                            <div className="loading-message">{loadingMessage}</div>
                        </div>
                    )}
                </div>
            </div>
            
            


<div className={showInstructions ? "instructions instructions-fadein" : "instructions instructions-fadeout"}>
      <h3>What to Expect:</h3>

        <li>Make sure your resume and job description are updated on the profile page.</li>
        <li>Click 'Check Alignment'.</li>
<br></br>   
        <li>The system will evaluate the alignment of your resume with the job description.</li>
        <li>You'll receive a detailed assessment of your qualifications versus job requirements.</li>
  
    <p><strong>Note:</strong> For best results, ensure your resume and job description are current and accurate.</p>
</div>

 

            <div className="generative-section" ref={loadingRef}>
                {questionsLoading && (
                    <div className={`result-container ${showSkeleton ? 'fadein' : 'fadeout'}`}>
                    <div className="result-inner">
                        <SkeletonLoader className="animated-background" />
                        </div>
                    </div>
                )}
                {!questionsLoading && questions && (
                    <div className={`result-container ${showContent ? 'fadein' : 'fadeout'}`}>
                     <div className="result-inner">
                        <h2>Generated Interview Questions:</h2>
                        <p dangerouslySetInnerHTML={{ __html: questions }}></p>
                        </div>
                        <CopyToClipboardButton textToCopy={questions} />
                        <button className="button-2" onClick={generateInterviewAnswers}>Generate Possible Answers</button>
                        
                         {answersLoading && (
                <div className="loading-container">
                    <img className="loading-gif" src={loadingGif} alt="Loading..." />
                    <div className="loading-message">{answersLoadingMessage}</div>
                </div>
            )}
            
            

                    </div>
                )}



{answersLoading && (
    <div ref={answersRef}  className={`result-container ${showAnswersSkeleton ? 'fadein' : 'fadeout'}`}>
        <div className='result-inner'>
        <SkeletonLoader className="animated-background" />
        </div>
    </div>
)}

{!answersLoading && answers && (
    <div className={`result-container ${showAnswersContent ? 'fadein' : 'fadeout'}`}>
        <div className='result-inner'>
        <h2>Generated Interview Answers:</h2>
        <p dangerouslySetInnerHTML={{ __html: answers }}></p>
        </div>
        <CopyToClipboardButton textToCopy={answers} />
    </div>
)}

            </div>
        </div>
    );
};

export default InterviewQuestionsGenerator;
