import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './Sidebar.css'; 
import { IoIosPaper } from "react-icons/io";
import { BiSearchAlt, BiSolidRightArrow, BiSolidDownArrow } from "react-icons/bi";
import { BsFillFileEarmarkPersonFill } from "react-icons/bs";
import { CgLogOut } from "react-icons/cg";
import { CgLogIn } from 'react-icons/cg'; 
import { useAuth0 } from "@auth0/auth0-react";
import { SiHandshake } from "react-icons/si"


const Sidebar = ({ isOpen, toggleSidebar, isMobileSidebarVisible }) => { 

  const [isResumeSectionExpanded, setResumeSectionExpanded] = useState(false);
  const toggleResumeSection = () => setResumeSectionExpanded(!isResumeSectionExpanded);

  let sidebarClassNames = `Sidebar sidebar-container ${isOpen ? "open" : ""} ${isMobileSidebarVisible ? "" : "hide"}`;
  const { logout, loginWithRedirect, isAuthenticated } = useAuth0();

  return (
    <aside className={sidebarClassNames}>
      <div className='sidebar-header'>
        <Link className='header-link' to="/"><h1>JobsAI</h1></Link>
        <button onClick={toggleSidebar} className={`hamburger hamburger--squeeze ${isOpen ? 'is-active' : ''}`} type="button">
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
      </div>

      <nav>
        <ul>
          {!isAuthenticated && <div className="sidebarloginlogout" onClick={() => loginWithRedirect({ returnTo: window.location.origin })}>Login <CgLogIn className='navicon'></CgLogIn></div>}
          {isAuthenticated && <div className="sidebarloginlogout" onClick={() => logout({ returnTo: window.location.origin })}>Logout <CgLogOut className='navicon'></CgLogOut></div>}
          <li><NavLink to="/profile" className={({ isActive }) => (isActive ? "active" : "")}>Profile</NavLink></li>
          
          {/* Resume Section */}
          <li className="resume-section-toggle" onClick={toggleResumeSection}>
            Resume Enhancement  {isResumeSectionExpanded ? <BiSolidDownArrow /> : <BiSolidRightArrow />} <IoIosPaper className='navicon' />
          </li>
          {isResumeSectionExpanded && (
            <>
              <li><NavLink to="/resume-alignment" className={({ isActive }) => (isActive ? "active subnav" : "subnav")}>Resume-Job Alignment <SiHandshake className='navicon' /></NavLink></li>
              <li><NavLink to="/resume-feedback" className={({ isActive }) => (isActive ? "active subnav" : "subnav")}>Resume Feedback <IoIosPaper className='navicon' /></NavLink></li>
              <li><NavLink to="/resume-rewrite" className={({ isActive }) => (isActive ? "active subnav" : "subnav")}>Resume Rewrite (GPT4) <IoIosPaper className='navicon' /></NavLink></li>
            </>
          )}
          
          {/* Other Links */}
          <li><NavLink to="/company-analysis-generator" className={({ isActive }) => (isActive ? "active" : "")}>Company Analysis <BiSearchAlt className='navicon' /></NavLink></li>
          <li><NavLink to="/interview-questions-generator" className={({ isActive }) => (isActive ? "active" : "")}>Interview Questions Generator <IoIosPaper className='navicon' /></NavLink></li>
          <li><NavLink to="/cover-letter-generator" className={({ isActive }) => (isActive ? "active" : "")}>Cover Letter Generator</NavLink></li>
          <li><NavLink to="/mock-interview" className={({ isActive }) => (isActive ? "active" : "")}>Mock Interview <BsFillFileEarmarkPersonFill className='navicon' /> </NavLink></li>

        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;