import React, { useState } from 'react';
import { AiFillCopy } from "react-icons/ai";

const CopyToClipboardButton = ({ textToCopy, buttonClass = 'button-2' }) => {
  const [copySuccess, setCopySuccess] = useState('Copy to Clipboard');

  const copyToClipboard = async () => {
    // Remove <br> tags from the text
    const textWithoutBr = textToCopy.replace(/<br>/g, '\n');
    try {
      await navigator.clipboard.writeText(textWithoutBr);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy text!');
    }
  };

  return (
    <div>
      <button className={buttonClass} onClick={copyToClipboard}>
        {copySuccess} <AiFillCopy className="navicon" />
      </button>
      {copySuccess && <div></div>}
    </div>
  );
};

export default CopyToClipboardButton;
