// src/Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <a href={`${process.env.PUBLIC_URL}/privacy-policy.html`} target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </a>
    </footer>
  );
};

export default Footer;
