import React, { useState } from 'react';
import './App.css';
import loadingGif from './loading.gif';
import './Sidebar.css'

const LoadingBanner = ({ isLoading, isLoadingType, loadingMessage, onClose }) => {
  const [animationStatus] = useState('bannerFadeIn');



  return (
      <div className={`loading-banner ${isLoadingType} ${animationStatus}`}>
          <div className='loading-container'>
          <div className="loading-message">{loadingMessage}</div>

          <img className="loading-gif" src={loadingGif} alt="Loading..." />
          <br></br>
          </div>

      </div>
  );
};


export default LoadingBanner;
