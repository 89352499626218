import React from 'react';
import './App.css';


const Dashboard = ( {baseUrl} ) => {

  return (  
      <div> <p>Dashboard</p> </div>
  );

};

export default Dashboard;
