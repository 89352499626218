import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import pdfjsLib from 'pdfjs-dist';
import mammoth from 'mammoth';

function FileUpload({ onTextExtracted }) {
  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file) return;

    const fileExtension = file.name.split('.').pop().toLowerCase();

    if (fileExtension === 'pdf') {
      const arrayBuffer = await file.arrayBuffer();
      const pdfDocument = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
      let text = '';

      for (let pageNumber = 1; pageNumber <= pdfDocument.numPages; pageNumber++) {
        const page = await pdfDocument.getPage(pageNumber);
        const content = await page.getTextContent();
        text += content.items.map((item) => item.str).join(' ');
      }

      onTextExtracted(text);
    } else if (fileExtension === 'docx') {
      mammoth.extractRawText({ arrayBuffer: await file.arrayBuffer() }).then((result) => {
        onTextExtracted(result.value);
      });
    }
  }, [onTextExtracted]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <p>Drag and drop your resume file here, or click to select a file.</p>
    </div>
  );
}

export default FileUpload;
