import { useState, useEffect} from 'react';
import './App.css';
import loadingGif from './loading.gif';
import CopyToClipboardButton from './CopyToClipboardButton';




const ResumeJobOptimizer = ({ baseUrl, resume }) => {

const BASE_URL = baseUrl;



  // This is the job description input and the resume feedback output
  const [jobDescription, setJobDescription] = useState("");
  




//sectionlist for parsing resume
  const [Sectionlist, setSectionlist] = useState("");

  // Optimized job description

  const [section1, setSection1] = useState("");
  const [optimizedSection1, setOptimizedSection1] = useState("");
  
  const [section2, setSection2] = useState("");
  const [optimizedSection2, setOptimizedSection2] = useState("");
  
  const [section3, setSection3] = useState("");
  const [optimizedSection3, setOptimizedSection3] = useState("");
  
  const [section4, setSection4] = useState("");
  const [optimizedSection4, setOptimizedSection4] = useState("");
  
  const [section5, setSection5] = useState("");
  const [optimizedSection5, setOptimizedSection5] = useState("");



  //button state switch
  const [section1ButtonText, setSection1ButtonText] = useState('Optimize');
  const [section2ButtonText, setSection2ButtonText] = useState('Optimize');
  const [section3ButtonText, setSection3ButtonText] = useState('Optimize');
  const [section4ButtonText, setSection4ButtonText] = useState('Optimize');
  const [section5ButtonText, setSection5ButtonText] = useState('Optimize');


  

  // loading messages

  const [message, setMessage] = useState('');

  // error declations

const [jobDescriptionError] = useState("");

//Error types


  // Error messages for each button
  const [section1Error, setSection1Error] = useState("");
  const [section2Error, setSection2Error] = useState("");
  const [section3Error, setSection3Error] = useState("");
  const [section4Error, setSection4Error] = useState("");
  const [section5Error, setSection5Error] = useState("");
  const [sectionlistError, setSectionlistError] = useState("");


//Reset Errors
const resetErrors = () => {


setSection1Error('');
setSection2Error('');
setSection3Error('');
setSection4Error('');
setSection5Error('');
setSectionlistError('');

}







// error types


  // Loading states for each button
  
  const [loadingSection1, setLoadingSection1] = useState(false);
  const [loadingSection2, setLoadingSection2] = useState(false);
  const [loadingSection3, setLoadingSection3] = useState(false);
  const [loadingSection4, setLoadingSection4] = useState(false);
  const [loadingSection5, setLoadingSection5] = useState(false);

  const [loadingSectionlist, setLoadingSectionlist] = useState(false);

  const [loadingParsedSection1, setLoadingParsedSection1] = useState(false);
  const [loadingParsedSection2, setLoadingParsedSection2] = useState(false);
  const [loadingParsedSection3, setLoadingParsedSection3] = useState(false);
  const [loadingParsedSection4, setLoadingParsedSection4] = useState(false);
  const [loadingParsedSection5, setLoadingParsedSection5] = useState(false);

  


  // Save job sections and job description to local storage

  useEffect(() => {
    const savedJobDescription = localStorage.getItem('jobDescription');
    if (savedJobDescription) {
      setJobDescription(savedJobDescription);
    }
  }, []);

  useEffect(() => {
    const savedSection1 = localStorage.getItem('section1');
    if (savedSection1) {
      setSection1(savedSection1);
    }
  }, []);

  useEffect(() => {
    const savedSection2 = localStorage.getItem('section2');
    if (savedSection2) {
      setSection2(savedSection2);
    }
  }, []);

  useEffect(() => {
    const savedSection3 = localStorage.getItem('section3');
    if (savedSection3) {
      setSection3(savedSection3);
    }
  }, []);

  useEffect(() => {
    const savedSection4 = localStorage.getItem('section4');
    if (savedSection4) {
      setSection4(savedSection4);
    }
  }, []);

  useEffect(() => {
    const savedSection5 = localStorage.getItem('section5');
    if (savedSection5) {
      setSection5(savedSection5);
    }
  }, []);

  //save sectionlist to local storage
  useEffect(() => {
    const savedSectionlist = localStorage.getItem('sectionlist');
    if (savedSectionlist) {
      setSectionlist(savedSectionlist);
    }
  }, []);


  // Save job description to local storage (part 2)

  useEffect(() => {
    localStorage.setItem('jobDescription', jobDescription);
  }, [jobDescription]);

  useEffect(() => {
    localStorage.setItem('section1', section1);
  }, [section1]);

  useEffect(() => {
    localStorage.setItem('section2', section2);
  }, [section2]);

  useEffect(() => {
    localStorage.setItem('section3', section3);
  }, [section3]);

  useEffect(() => {
    localStorage.setItem('section4', section4);
  }, [section4]);

  useEffect(() => {
    localStorage.setItem('section5', section5);
  }, [section5]);

  //save sectionlist to local storage
  useEffect(() => {
    localStorage.setItem('Sectionlist', Sectionlist);
  }, [Sectionlist]);


  // Error messages

  useEffect(() => {
    if (jobDescriptionError) {
      setMessage(jobDescriptionError);
    }
  }, [jobDescriptionError]);

  useEffect(() => {
    if (section1Error) {
      setMessage(section1Error);
    }
  }, [section1Error]);

  useEffect(() => {
    if (section2Error) {
      setMessage(section2Error);
    }
  }, [section2Error]);

  useEffect(() => {
    if (section3Error) {
      setMessage(section3Error);
    }
  }, [section3Error]);

  useEffect(() => {
    if (section4Error) {
      setMessage(section4Error);
    }

  }, [section4Error]);

  useEffect(() => {
    if (section5Error) {
      setMessage(section5Error);
    }
  }, [section5Error]);

  //section list error

  useEffect(() => {
    if (sectionlistError) {
      setMessage(sectionlistError);
    }
  }, [sectionlistError]);



  // Error type definitions


  //Loading messages

  const startTimer = () => {
    setTimeout(() => {
      setMessage("Hang tight! We're still generating your results.");
    }, 15000);
  };






//begining of GPT functions


//begining of resume section list function
const parseResumeForJobSectionList = async () => {
  resetErrors();

  if (!setSectionlist) {
    setSectionlistError('');
    return;
  }

  try {
    setLoadingSectionlist(true);
    startTimer();

    

    const fetchPromise = fetch(`${BASE_URL}/parse-resume-for-job-section-list`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },

      body: JSON.stringify({ resume: resume.replace(/\n/g, ' ') }),
    });

    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error('Operation took too long. Please try again. If the issue persists, consider entering less text in the input fields.')), 30000)
    );

    const response = await Promise.race([fetchPromise, timeoutPromise]);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const jobSectionlistMessage = await response.json();
    setSectionlist(jobSectionlistMessage.jobSectionlist);

  } catch (error) {
    console.error('There was a problem with the fetch operation: ', error);
    setSectionlistError(error.message);
  } finally {
    
    setMessage('');
    setLoadingSectionlist(false);
  }
};

//begining of parse resume for job section 1, 2, 3, 4, 5
const parseResumeForJobSection1 = async () => {
  resetErrors();

  if (!setSection1) {
    setSection1Error('');
    return;
  }

  try {
    setLoadingParsedSection1(true);
    startTimer();

    const fetchPromise = fetch(`${BASE_URL}/parse-resume-for-job-section1`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },

      body: JSON.stringify({ Sectionlist, resume: resume.replace(/\n/g, ' ') }),
    });

    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error('Operation took too long. Please try again. If the issue persists, consider entering less text in the input fields.')), 30000)
    );

    const response = await Promise.race([fetchPromise, timeoutPromise]);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const jobSection1Message = await response.json();
    setSection1(jobSection1Message.jobSection);
  } catch (error) {
    console.error('There was a problem with the fetch operation: ', error);
    setSection1Error(error.message);
  } finally {
    setLoadingParsedSection1(false);
    setMessage('');
  }
};

const parseResumeForJobSection2 = async () => {
  resetErrors();

  if (!setSection2) {
    setSection2Error('');
    return;
  }

  try {
    setLoadingParsedSection2(true);
    startTimer();

    const fetchPromise = fetch(`${BASE_URL}/parse-resume-for-job-section2`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },

      body: JSON.stringify({ Sectionlist, resume: resume.replace(/\n/g, ' ') }),
    });

    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error('Operation took too long. Please try again. If the issue persists, consider entering less text in the input fields.')), 30000)
    );

    const response = await Promise.race([fetchPromise, timeoutPromise]);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const jobSection2Message = await response.json();
    setSection2(jobSection2Message.jobSection);
  } catch (error) {
    console.error('There was a problem with the fetch operation: ', error);
    setSection2Error(error.message);
  } finally {
    setLoadingParsedSection2(false);
    setMessage('');
  }
};

const parseResumeForJobSection3 = async () => {
  resetErrors();

  if (!setSection3) {
    setSection3Error('');
    return;
  }

  try {
    setLoadingParsedSection3(true);
    startTimer();

    const fetchPromise = fetch(`${BASE_URL}/parse-resume-for-job-section3`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },

      body: JSON.stringify({ Sectionlist, resume: resume.replace(/\n/g, ' ') }),
    });

    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error('Operation took too long. Please try again. If the issue persists, consider entering less text in the input fields.')), 30000)
    );

    const response = await Promise.race([fetchPromise, timeoutPromise]);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const jobSection3Message = await response.json();
    setSection3(jobSection3Message.jobSection);
  } catch (error) {
    console.error('There was a problem with the fetch operation: ', error);
    setSection3Error(error.message);
  } finally {
    setLoadingParsedSection3(false);
    setMessage('');
  }
};

const parseResumeForJobSection4 = async () => {
  resetErrors();

  if (!setSection4) {
    setSection4Error('');
    return;
  }

  try {
    setLoadingParsedSection4(true);
    startTimer();

    const fetchPromise = fetch(`${BASE_URL}/parse-resume-for-job-section4`, {

      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },

      body: JSON.stringify({ Sectionlist, resume: resume.replace(/\n/g, ' ') }),
    });

    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error('Operation took too long. Please try again. If the issue persists, consider entering less text in the input fields.')), 30000)
    );

    const response = await Promise.race([fetchPromise, timeoutPromise]);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const jobSection4Message = await response.json();
    setSection4(jobSection4Message.jobSection);
  } catch (error) {
    console.error('There was a problem with the fetch operation: ', error);
    setSection4Error(error.message);
  } finally {
    setLoadingParsedSection4(false);
    setMessage('');
  }
};


const parseResumeForJobSection5 = async () => {
  resetErrors();

  if (!setSection5) {
    setSection5('');
    return;
  }

  try {
    setLoadingParsedSection5(true);
    startTimer();

    const fetchPromise = fetch(`${BASE_URL}/parse-resume-for-job-section5`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },

      body: JSON.stringify({Sectionlist ,resume: resume.replace(/\n/g, ' ') }),
    });

    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error('Operation took too long. Please try again. If the issue persists, consider entering less text in the input fields.')), 30000)
    );

    const response = await Promise.race([fetchPromise, timeoutPromise]);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const jobSection5Message = await response.json();
    setSection5(jobSection5Message.jobSection);
  } catch (error) {
    console.error('There was a problem with the fetch operation: ', error);
    setSection5Error(error.message);
  } finally {
    setLoadingParsedSection5(false);
    setMessage('');
  }
};





//end of job parse  functions






//begining of optimize functions

const optimizeSection1 = async () => {
  setSection1Error('');

  if (!jobDescription || !section1) {
    setSection1Error('Please make sure to populate the job description and resume section 1 input.');
    return;
  }

  try {
    setLoadingSection1(true);
    startTimer();

    if (section1ButtonText === 'Optimize') {
      setSection1ButtonText('Regenerate Optimized Section');
    } 

    const fetchPromise = fetch(`${BASE_URL}/optimize-resume-section`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        jobDescription: jobDescription.replace(/\n/g, ' '), 
        section: section1.replace(/\n/g, ' ')
      }),
    });

    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error('Operation took too long. Please try again. If the issue persists, consider entering less text in the input fields.')), 30000)
    );

    const response = await Promise.race([fetchPromise, timeoutPromise]);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const optimizationMessage = await response.json();
    setOptimizedSection1(optimizationMessage.optimizedSection);
  } catch (error) {
    console.error('There was a problem with the fetch operation: ', error);
    setSection1Error(error.message);
  } finally {
    setLoadingSection1(false);
  }
};

const optimizeSection2 = async () => {
  setSection2Error('');

  if (!jobDescription || !section2) {
    setSection2Error('Please make sure to populate the job description and resume section 2 input.');
    return;
  }

  try {
    setLoadingSection2(true);
    startTimer();

    if (section2ButtonText === 'Optimize') {
      setSection2ButtonText('Regenerate Optimized Section');
    } 

    const fetchPromise = fetch(`${BASE_URL}/optimize-resume-section`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        jobDescription: jobDescription.replace(/\n/g, ' '), 
        section: section2.replace(/\n/g, ' ')
      }),
    });

    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error('Operation took too long. Please try again. If the issue persists, consider entering less text in the input fields.')), 30000)
    );

    const response = await Promise.race([fetchPromise, timeoutPromise]);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const optimizationMessage = await response.json();
    setOptimizedSection2(optimizationMessage.optimizedSection);
  } catch (error) {
    console.error('There was a problem with the fetch operation: ', error);
    setSection2Error(error.message);
  } finally {
    setLoadingSection2(false);
  }
};

const optimizeSection3 = async () => {
  setSection3Error('');

  if (!jobDescription || !section3) {
    setSection3Error('Please make sure to populate the job description and resume section 3 input.');
    return;
  }

  try {
    setLoadingSection3(true);
    startTimer();

    if (section3ButtonText === 'Optimize') {
      setSection3ButtonText('Regenerate Optimized Section');
    } 


    const fetchPromise = fetch(`${BASE_URL}/optimize-resume-section`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        jobDescription: jobDescription.replace(/\n/g, ' '), 
        section: section3.replace(/\n/g, ' ')
      }),
    });

    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error('Operation took too long. Please try again. If the issue persists, consider entering less text in the input fields.')), 30000)
    );

    const response = await Promise.race([fetchPromise, timeoutPromise]);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const optimizationMessage = await response.json();
    setOptimizedSection3(optimizationMessage.optimizedSection);
  } catch (error) {
    console.error('There was a problem with the fetch operation: ', error);
    setSection3Error(error.message);
  } finally {
    setLoadingSection3(false);
  }
};

const optimizeSection4 = async () => {
  setSection4Error('');

  if (!jobDescription || !section4) {
    setSection4Error('Please make sure to populate the job description and resume section 4 input.');
    return;
  }

  try {
    setLoadingSection4(true);
    startTimer();

    if (section4ButtonText === 'Optimize') {
      setSection4ButtonText('Regenerate Optimized Section');
    } 

    const fetchPromise = fetch(`${BASE_URL}/optimize-resume-section`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        jobDescription: jobDescription.replace(/\n/g, ' '), 
        section: section4.replace(/\n/g, ' ')
      }),
    });

    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error('Operation took too long. Please try again. If the issue persists, consider entering less text in the input fields.')), 30000)
    );

    const response = await Promise.race([fetchPromise, timeoutPromise]);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const optimizationMessage = await response.json();
    setOptimizedSection4(optimizationMessage.optimizedSection);
  } catch (error) {
    console.error('There was a problem with the fetch operation: ', error);
    setSection4Error(error.message);
  } finally {
    setLoadingSection4(false);
  }
};

const optimizeSection5 = async () => {
  setSection5Error('');

  if (!jobDescription || !section5) {
    setSection5Error('Please make sure to populate the job description and resume section 5 input.');
    return;
  }

  try {
    setLoadingSection5(true);
    startTimer();

    if (section5ButtonText === 'Optimize') {
      setSection5ButtonText('Regenerate Optimized Section');
    } 
    

    const fetchPromise = fetch(`${BASE_URL}/optimize-resume-section`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        jobDescription: jobDescription.replace(/\n/g, ' '), 
        section: section5.replace(/\n/g, ' ')
      }),
    });

    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error('Operation took too long. Please try again. If the issue persists, consider entering less text in the input fields.')), 30000)
    );

    const response = await Promise.race([fetchPromise, timeoutPromise]);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const optimizationMessage = await response.json();
    setOptimizedSection5(optimizationMessage.optimizedSection);
  } catch (error) {
    console.error('There was a problem with the fetch operation: ', error);
    setSection5Error(error.message);
  } finally {
    setLoadingSection5(false);
  }
};


//end of optimize functions


//end of all functions
  
//begining of render

return (
<div className="ComponentContainer">

<div className="ResumeOptimizer">
    <h1>Resume-Job Optimization</h1> 
     <div className="input-section">
      
     <h2>Job Description</h2>
        <div className="input-form">
        <div className="input-group">

          <h3>Begin by typing the job description you're looking to optimize your resume for. 
            Copy and paste the role's description, responsibilities, and all. The better we understand the role,
             the more tailored your resume will be.
            <br></br><br></br>
          Next, fill out each individual section related to the job. 
          This information will be used to create a customized section 
          of your resume that directly addresses the requirements of your 
          target job. </h3>
          <textarea
            className="textarea-large"
            value={jobDescription}
            onChange={(e) => setJobDescription(e.target.value)}
          />
        </div>
      </div>

      {message && (
        <div className="message-container">
          <div className="message">{message}</div>
        </div>
      )}
      
      
      <h2 className='resumesections'>Resume Sections:</h2>

          {/* Section List */}
          <div className="input-form section">
          <div className="input-group">
            <label className="inputlabel">Resume Section List</label>
            <textarea
              className="textarea-large"
              value={Sectionlist}
              onChange={(e) => setSectionlist(e.target.value)}
            />



            {section1Error && (
              <div className="error-message">{section1Error}</div>
            )}



            {loadingSectionlist && (
              <div className="loading-container">
                <img className="loading-gif" src={loadingGif} alt="Loading..." />
                <div className="loading-message">Parsing Resume Section List...</div>
              </div>
            )}

            {loadingSection1 && (
              <div className="loading-container">
                <img className="loading-gif" src={loadingGif} alt="Loading..." />
                <div className="loading-message">Parsing Sections...</div>
              </div>
            )}

          </div>

          <div className="button-group">
            <button type="button" className='button-2' onClick={() => {
              parseResumeForJobSectionList();

            }}>Parse Resume Sections</button>

            <button type="button" className='button-2' onClick={() => {

              parseResumeForJobSection1();
              parseResumeForJobSection2();
              parseResumeForJobSection3();
              parseResumeForJobSection4();
              parseResumeForJobSection5();
            }}>Parse Job Sections</button>

            </div>


          </div>

          {/* Section 1 */}
          <div className="input-form section">
          <div className="input-group">
            <label className="inputlabel">Resume Section 1</label>
            <textarea
              className="textarea-large"
              value={section1}
              onChange={(e) => setSection1(e.target.value)}
            />



            {section1Error && (
              <div className="error-message">{section1Error}</div>
            )}
            {optimizedSection1 && (
              <div>
                <h3>Optimized Section 1:</h3>
                <p className='optimized-section' contentEditable dangerouslySetInnerHTML={{ __html: optimizedSection1 }}></p>
                <CopyToClipboardButton textToCopy={optimizedSection1} />

              </div>
            )}



            {loadingSection1 && (
              <div className="loading-container">
                <img className="loading-gif" src={loadingGif} alt="Loading..." />
                <div className="loading-message">Optimizing Section 1...</div>
              </div>
            )}

            {loadingParsedSection1 && (
              <div className="loading-container">
                <img className="loading-gif" src={loadingGif} alt="Loading..." />
                <div className="loading-message">Parsing Section 1...</div>
                </div>  
            )}

          </div>

          <button className="button-2" onClick={optimizeSection1}>
              {section1ButtonText}
            </button>

          </div>

          {/* Section 2 */}
          <div className="input-form section">
          <div className="input-group">
            <label className="inputlabel">Resume Section 2</label>
            <textarea
              className="textarea-large"
              value={section2}
              onChange={(e) => setSection2(e.target.value)}
            />


            {loadingSection2 && (
              <div className="loading-container">
                <img className="loading-gif" src={loadingGif} alt="Loading..." />
                <div className="loading-message">Optimizing Section 2...</div>
              </div>
            )}

            {loadingParsedSection2 && (
              <div className="loading-container">
                <img className="loading-gif" src={loadingGif} alt="Loading..." />
                <div className="loading-message">Parsing Section 1...</div>
                </div>  
            )}


            {section2Error && (
              <div className="error-message">{section2Error}</div>
            )}
            {optimizedSection2 && (
              <div>
                <h3>Optimized Section 2:</h3>
                <p className='optimized-section' contentEditable dangerouslySetInnerHTML={{ __html: optimizedSection2 }}></p>   
                <CopyToClipboardButton textToCopy={optimizedSection2} />
           
                </div>
            )}


          </div>

          <button className="button-2" onClick={optimizeSection2}>
            {section2ButtonText}
          </button>


          </div>

          {/* Section 3 */}
          <div className="input-form section">
          <div className="input-group">

            <label className="inputlabel">Resume Section 3</label>
            <textarea
              className="textarea-large"
              value={section3}
              onChange={(e) => setSection3(e.target.value)}
            />


            {loadingSection3 && (
              <div className="loading-container">
                <img className="loading-gif" src={loadingGif} alt="Loading..." />
                <div className="loading-message">Optimizing Section 3...</div>
              </div>
            )}

            {loadingParsedSection3 && (
              <div className="loading-container">
                <img className="loading-gif" src={loadingGif} alt="Loading..." />
                <div className="loading-message">Parsing Section 1...</div>
                </div>  
            )}


            {section3Error && (
              <div className="error-message">{section3Error}</div>
            )}
            {optimizedSection3 && (
              <div>
                <h3>Optimized Section 3:</h3>
                <p className='optimized-section' contentEditable dangerouslySetInnerHTML={{ __html: optimizedSection3 }}></p>              
                <CopyToClipboardButton textToCopy={optimizedSection3} />

              </div>
            )}


          </div>

          <button className="button-2" onClick={optimizeSection3}>
              {section3ButtonText}
              </button>


          </div>

          {/* Section 4 */}
          <div className="input-form section">
          <div className="input-group">
            <label className="inputlabel">Resume Section 4</label>
            <textarea
              className="textarea-large"
              value={section4}
              onChange={(e) => setSection4(e.target.value)}
            />


            {loadingSection4 && (
              <div className="loading-container">
                <img className="loading-gif" src={loadingGif} alt="Loading..." />
                <div className="loading-message">Optimizing Section 4...</div>
              </div>
            )}

            {loadingParsedSection4 && (
              <div className="loading-container">
                <img className="loading-gif" src={loadingGif} alt="Loading..." />
                <div className="loading-message">Parsing Section 1...</div>
                </div>  
            )}


            {section4Error && (
              <div className="error-message">{section4Error}</div>
            )}
            {optimizedSection4 && (
              <div>
                <h3>Optimized Section 4:</h3>
                <p className='optimized-section' contentEditable dangerouslySetInnerHTML={{ __html: optimizedSection4 }}></p>      
                <CopyToClipboardButton textToCopy={optimizedSection4} />
        
              </div>
            )}


          </div>

          <button className="button-2" onClick={optimizeSection4}>
            {section4ButtonText}
          </button>

          </div>

        
          {/* Section 5 */}
          <div className="input-form section">
          <div className="input-group">
            <label className="inputlabel">Resume Section 5</label>
            <textarea
              className="textarea-large"
              value={section5}
              onChange={(e) => setSection5(e.target.value)}
            />


            {loadingSection5 && (
              <div className="loading-container">
                <img className="loading-gif" src={loadingGif} alt="Loading..." />
                <div className="loading-message">Optimizing Section 5...</div>
              </div>
            )}

            {loadingParsedSection5 && (
              <div className="loading-container">
                <img className="loading-gif" src={loadingGif} alt="Loading..." />
                <div className="loading-message">Parsing Section 1...</div>
                </div>  
            )}

            {section5Error && (
              <div className="error-message">{section5Error}</div>
            )}
            {optimizedSection5 && (
              <div>
                <h3>Optimized Section 5:</h3>
                <p className='optimized-section' contentEditable dangerouslySetInnerHTML={{ __html: optimizedSection5 }}></p>
                <CopyToClipboardButton textToCopy={optimizedSection5} />

              </div>
            )}


            
          </div>

          <button className="button-2" onClick={optimizeSection5}>
              {section5ButtonText}
            </button>

          </div> 
        </div>

  </div>
      
      
</div>
  );
  
  
  
  



};

export default ResumeJobOptimizer;